<template>
  <button
    class="mx-4 my-0 p-3"
    :class="'text-' + iconColor"
    @click.prevent.stop="share"
    :disabled="disabled"
  >
    <ShareIcon
      v-if="type == 'icon'"
      class="w-5 h-5"
    />
  </button>
  <Button
    v-if="type == 'button'"
    label="common.share"
    class="w-full p-0 m-0"
    outline
    @click.prevent.stop="share"
    :disabled="disabled"
  />
  <ModalMenu v-model="modalMenuVisible" :title="t(props.viewTitle)" :buttons="modalMenuButtons" />
</template>

<script lang="ts" setup>
import { Button } from '@/components/ui'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n';
import ModalMenu from '@/components/ModalMenu.vue';
import ShareIcon from '@/assets/icons/share.svg'
import { successToast } from '@/utils/toast'

const { t } = useI18n()
const modalMenuVisible = ref(false)

const props = withDefaults(defineProps<{
  viewTitle: string,
  shareTitle: string,
  shareDescription?: string|undefined,
  shareUrl: string,
  disabled?: boolean,
  iconColor?: string,
  type?: 'icon' | 'button',
}>(), {
  disabled: false,
  iconColor: 'blue-550',
  shareDescription: undefined,
  type: 'icon',
})

const emit = defineEmits<{
  (e: 'clicked'): void,
  (e: 'closed'): void,
}>()

const modalMenuButtons = computed(() => [
  {
    text: String(t('share.copy_to_clipboard')),
    handler: () => copyBtnTapped(),
  },
  {
    text: String(t('share.facebook')),
    handler: () => shareFacebook(),
  },
  {
    text: String(t('share.twitter')),
    handler: () => shareTwitter(),
  },
  {
    text: String(t('share.email')),
    handler: () => shareEmail(),
  },
])

const getUrl = computed((): string => {
  if (!props.shareUrl) {
    return ''
  }
  if (props.shareUrl.includes('http://') || props.shareUrl.includes('https://')) {
    return props.shareUrl
  }
  return window?.location.origin + props.shareUrl
})

const isMobile = computed((): boolean => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
})

const share = async (): Promise<void> => {
  emit('clicked')
  if (isMobile.value && typeof navigator.share !== 'undefined') {
    mobileShare()
    return
  }

  modalMenuVisible.value = true
}

const shareFacebook = (): void => {
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${getUrl.value}`, '_blank')
}

const shareTwitter = (): void => {
  window.open(`https://twitter.com/intent/tweet?url=${getUrl.value}&text=${props.shareDescription}`, '_blank')
}

const shareEmail = (): void => {
  window.open(`mailto:?subject=[rosypet.com] ${props.shareTitle}&body=${getUrl.value}`)
}

const copyBtnTapped = async (): Promise<void> => {
  await navigator.clipboard.writeText(getUrl.value)
  await successToast('success_messages.copied_to_clipboard')
}

const mobileShare = async (): Promise<void> => {
  const title = props.shareTitle ? String(t(props.shareTitle)) : ''
  const text = props.shareDescription ? String(t(props.shareDescription)) : ''
  try {
    await navigator.share({
      url: getUrl.value,
      title,
      text,
    })
  } catch (e: any) {
    if (e.toString().includes('AbortError')) {
      return
    } else {
      throw e
    }
  }
}

watch(modalMenuVisible, (value) => {
  if (!value) emit('closed')
})
</script>