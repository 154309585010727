<template>
  <teleport to="head">
    <MetaTags :meta-data-fn="metaDataFn" />
    <ConfigState />
  </teleport>
  <div class="max-w-full max-h-[100vh] min-h-[100vh] flex flex-col overflow-x-hidden overflow-y-hidden">
    <Header />
    <main id="scroll" class="flex-grow overflow-y-auto flex flex-col" :class="{ 'p-8': !noPadding }">
      <RouterView class="flex-grow" />
    </main>
    <ConfirmPopup />
    <SpinnerModal :show-spinner="showSpinner" :loading-message="spinnerMessage" :loading-message-params="spinnerMessageParams" />
    <transition name="fade">
      <CallOverlay v-if="showCallOverlay && callViewModel" :callViewModel="callViewModel" />
    </transition>
    <transition name="fade">
      <IncomingCallOverlay v-if="showIncomingCallOverlay && incomingCall" :incomingCall="incomingCall" />
    </transition>
    <OverlayPopup v-for="key in overlayPopups" :key="key" :overlay-popup-key="key" @closed="closeOverlayPopup(key)" />
    <PwaPopover v-if="showPwaPopover" @update:model-value="showPwaPopover = $event" />
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  getCurrentInstance,
  onMounted,
  onServerPrefetch,
  ref,
  watch
} from 'vue'
import FeatureService from '@/services/FeatureService.js'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/UserStore.js'
import { useI18n } from 'vue-i18n'
import { ConfigState } from '@/components/ConfigState'
import { MetaTags } from '@/components/MetaTags'
import { useMeta } from '@/composables/Meta'
import Header from '@/components/Header.vue'
import Config from '@/utils/config'
import { useCacheStore } from '@/stores/CacheStore'
import ConfirmPopup from '@/components/ConfirmPopup.vue'
import { useAudio } from '@/composables/Audio'
import { useCallOverlayDisplay } from '@/composables/CallOverlay'
import { useSockets } from '@/composables/Sockets'
import { useSpinnerDisplay } from '@/composables/Spinner'
import { useOverlayPopup } from '@/composables/OverlayPopup'
import { useToastDisplay } from '@/composables/Toast'
import { useAppEventHandlers } from '@/composables/AppEventHandlers'
import { useOneSignal } from '@/composables/OneSignal'
import { isIos, isSafari } from '@/utils/browser'
import PwaPopover from '@/components/PwaPopover.vue'

const { metaDataFn, updateMetaData } = useMeta()
const { t } = useI18n()
const app = getCurrentInstance()
useAppEventHandlers()
useAudio()
const cacheStore = useCacheStore()
const fullUrl = app?.appContext.config.globalProperties.fullUrl
const userStore = useUserStore()
const route = useRoute()
const showPwaPopover = ref(false)
const { CallOverlay, IncomingCallOverlay, callViewModel, incomingCall, showCallOverlay, showIncomingCallOverlay } = useCallOverlayDisplay()
const { SpinnerModal, showSpinner, spinnerMessage, spinnerMessageParams } = useSpinnerDisplay()
const { OverlayPopup, closeOverlayPopup, overlayPopups } = useOverlayPopup()
useToastDisplay()
useOneSignal()
useSockets()

onMounted(() => {
  FeatureService.loadFeatures()
  if (isIos() && isSafari() && !userStore.pwaPopoverShown && Config.environment != 'e2e') {
    showPwaPopover.value = true
  }
})

onServerPrefetch(async () => {
  await FeatureService.loadFeatures().catch(() => {})
})

const resetMetaData = () => {
  return updateMetaData({
    language: computed(() => userStore.language),
    title: computed(() => t('common.meta_title')),
    description: computed(() => t('common.Meta_description')),
    image: computed(() => t('common.seo.image')),
    noIndex: computed(() => false),
    url: computed(() => (import.meta.env.SSR ? fullUrl : window.location.href)),
    next: computed(() => undefined),
    prev: computed(() => undefined),
  })
}

resetMetaData()

const country = computed((): string => {
  return (route.params.locale as string)?.split('-')[1] ?? userStore.country ?? Config.availableCountries[0]
})

const language = computed((): string => {
  return (route.params.locale as string)?.split('-')[0] ?? userStore.language ?? Config.languages[0].lang
})

const noPadding = computed(() => {
  return route.meta?.noPadding ?? false
})

onServerPrefetch(() => {
  if (!language.value) return
  userStore.language = language.value
})

watch(
  () => language.value,
  (newLanguage) => {
    if (!newLanguage) return
    userStore.language = newLanguage
  },
  { immediate: true }
)

watch(
  () => country.value,
  (newCountry) => {
    if (!newCountry) return
    userStore.country = newCountry
  },
  { immediate: true }
)

watch(
  () => userStore.language,
  (newLanguage) => {
    if (!newLanguage) return
    cacheStore.languageChanged(newLanguage)
  },
  { immediate: true }
)
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>