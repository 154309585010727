import { RouteRecordRaw } from 'vue-router'
import Finances from './finances/routes'
import MessageCenter from './message-center/routes'
import Search from './search/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'action-center',
    name: 'action-center',
    component: () => import('./ActionCenter.vue'),
    meta: { header: { blueTheme: true, title: 'menu.action_center' } }
  },
  {
    path: 'contact',
    name: 'contact',
    component: () => import('./Contact.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'content/:key',
    name: 'content',
    component: () => import('./content/Content.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'finances',
    children: Finances,
  },
  {
    path: 'investors',
    name: 'investors',
    component: () => import('./Investors.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'message-center',
    children: MessageCenter,
  },
  {
    path: 'page-not-found',
    name: 'page-not-found',
    component: () => import('./PageNotFound.vue'),
  },
  {
    path: 'search',
    children: Search,
  },
  {
    path: 'settings',
    name: 'settings',
    component: () => import('./Settings.vue'),
    meta: { header: { showBackButton: true } }
  },
  {
    path: 'video-consultation/:expert_id/:id',
    name: 'video-consultation',
    component: () => import('./VideoConsultation.vue'),
    // TODO: PaymentGuard
  },
]

export default routes
