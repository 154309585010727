import { IMenu } from '@/models/IMenu'

export const IntroMenus: IMenu[] = [
  { title: 'common.log_in', to: { name: 'login' }, subMenus: [] },
  { title: 'common.register', to: { name: 'onboarding-select' }, subMenus: [] },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', to: { name: 'content', params: { key: 'privacy' } } },
      { title: 'menu.information.terms', to: { name: 'content', params: { key: 'terms' } } },
      { title: 'menu.information.imprint', to: { name: 'content', params: { key: 'imprint' } } },
      { title: 'menu.information.functions_pricing', to: { name: 'content', params: { key: 'pricing' } } },
      { title: 'menu.information.faq', to: { name: 'content', params: { key: 'faq' } } },
      { title: 'menu.information.about', to: { name: 'content', params: { key: 'about' } } },
      { title: 'menu.information.blog', to: { name: 'content', params: { key: 'blog' } } },
      { title: 'menu.information.investors', to: { name: 'investors' } },
      { title: 'menu.information.contact', to: { name: 'contact' } },
    ],
  },
]

export const PetOwnerMenus: IMenu[] = [
  {
    title: 'menu.profile.title',
    subMenus: [
      { title: 'menu.profile.my_profile', to: { name: 'pet-owner-profile' }, subMenus: [] },
      { title: 'menu.profile.settings', to: { name: 'settings' } },
      { title: 'menu.profile.finances', to: { name: 'finances' } },
    ],
  },
  { title: 'menu.my_pets', to: { name: 'pet-owner-pets' }, subMenus: [] },
  { title: 'menu.my_experts', to: { name: 'pet-owner-experts' }, subMenus: [] },
  {
    title: 'menu.functionality.title',
    subMenus: [
      { title: 'menu.functionality.video_call', to: { name: 'search', query: { tags: 'video' } } },
      { title: 'menu.messages', to: { name: 'message-center' } },
    ],
  },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', to: { name: 'content', params: { key: 'privacy' } } },
      { title: 'menu.information.terms', to: { name: 'content', params: { key: 'terms' } } },
      { title: 'menu.information.imprint', to: { name: 'content', params: { key: 'imprint' } } },
      { title: 'menu.information.pricing', to: { name: 'content', params: { key: 'pricing' } } },
      { title: 'menu.information.faq', to: { name: 'content', params: { key: 'faq' } } },
      { title: 'menu.information.about', to: { name: 'content', params: { key: 'about' } } },
      { title: 'menu.information.blog', to: { name: 'content', params: { key: 'blog' } } },
      { title: 'menu.information.investors', to: { name: 'investors' } },
      { title: 'menu.information.contact', to: { name: 'contact' } },
    ],
  },
  { title: 'menu.logout', subMenus: [] },
]

export const PetExpertMenus: IMenu[] = [
  { title: 'menu.dashboard', to: { name: 'expert-dashboard' }, subMenus: [] },
  {
    title: 'menu.profile.title',
    subMenus: [
      { title: 'menu.profile.my_profile', to: { name: 'expert-profile' }, subMenus: [] },
      { title: 'menu.profile.settings', to: { name: 'settings' } },
      { title: 'menu.profile.finances', to: { name: 'finances' } },
    ],
  },
  { title: 'menu.my_pet_owners', to: { name: 'expert-pet-owners' } },
  {
    title: 'menu.functionality.title',
    subMenus: [
      { title: 'menu.functionality.video_call', to: { name: 'expert-profile-video-call' } },
      { title: 'menu.messages', to: { name: 'message-center' } },
      { title: 'menu.analytics', to: { name: 'expert-analytics' } },
    ],
  },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', to: { name: 'content', params: { key: 'privacy' } } },
      { title: 'menu.information.terms', to: { name: 'content', params: { key: 'terms' } } },
      { title: 'menu.information.imprint', to: { name: 'content', params: { key: 'imprint' } } },
      { title: 'menu.information.pricing', to: { name: 'content', params: { key: 'pricing' } } },
      { title: 'menu.information.faq', to: { name: 'content', params: { key: 'faq' } } },
      { title: 'menu.information.about', to: { name: 'content', params: { key: 'about' } } },
      { title: 'menu.information.blog', to: { name: 'content', params: { key: 'blog' } } },
      { title: 'menu.information.investors', to: { name: 'investors' } },
      { title: 'menu.information.contact', to: { name: 'contact' } },
    ],
  },
  { title: 'menu.logout', to: { name: '' }, subMenus: [] },
]

export const AdminMenus: IMenu[] = [
  { title: 'menu.dashboard', to: { name: 'home' }, subMenus: [] },
  { title: 'menu.profile.settings', to: { name: 'settings' }, subMenus: [] },
  {
    title: 'menu.information.title',
    subMenus: [
      { title: 'menu.information.privacy', to: { name: 'content', params: { key: 'privacy' } } },
      { title: 'menu.information.terms', to: { name: 'content', params: { key: 'terms' } } },
      { title: 'menu.information.imprint', to: { name: 'content', params: { key: 'imprint' } } },
      { title: 'menu.information.pricing', to: { name: 'content', params: { key: 'pricing' } } },
      { title: 'menu.information.faq', to: { name: 'content', params: { key: 'faq' } } },
      { title: 'menu.information.about', to: { name: 'content', params: { key: 'about' } } },
      { title: 'menu.information.investors', to: { name: 'investors' } },
      { title: 'menu.information.contact', to: { name: 'contact' } },
    ],
  },
  { title: 'menu.logout' },
]
