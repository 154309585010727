import { type Router, type RouteLocationNormalized, type RouteLocationRaw } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import Config from '@/utils/config'

export const home = () => {
  return (router: Router, to: RouteLocationNormalized, next: (location?: RouteLocationRaw) => void) => {
    if (Config.ssr) return next()

    const userStore = useUserStore()

    switch (userStore.user?.role) {
      case 'admin':
        return next({ name: 'admin.dashboard' })
      case 'expert':
        return next({ name: 'expert-dashboard' })
      default:
        return next()
    }
  }
}
